import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import { Button, FormField, Header, Input, Label } from "semantic-ui-react";

export default function MyFileInput({ label, ...props }) {
  const [field, meta, helpers] = useField(props);
  const formikProps = useFormikContext();
  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <Button as='label' htmlFor='file' type='button'>
        {field.value ? field.value.name : props.placeholder}
      </Button>

      <input
        {...props}
        hidden
        onChange={(event) => {
          formikProps.setFieldValue(props.name, event.currentTarget.files[0]);
        }}
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
}
