import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import UserForm from "./UserForm";

export default function UserFormModal({ workspaceId }) {
  return (
    <ModalWrapper size='small' header='User'>
      <UserForm workspaceId={workspaceId} />
    </ModalWrapper>
  );
}
