function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

function getRandomLogo() {
  return `https://picsum.photos/id/${getRandomInt(100, 200)}/100?grayscale`;
}
export const workspacesData = [
  {
    id: 1,
    title: "ZAD Studio",
    description: "Architecture design consultancy",
    category: "design",
    date: new Date("2015-08-07"),
    logo: getRandomLogo(),
  },
  {
    id: 2,
    title: "ZAD Reality",
    description: "Interactive design services",
    category: "technology",
    date: new Date("2020-12-20"),
    logo: getRandomLogo(),
  },
  {
    id: 3,
    title: "ZAD Studio",
    description: "Architecture design consultancy",
    category: "design",
    date: new Date("2015-08-07"),
    logo: getRandomLogo(),
  },
];

export const projectStepItems = [
  {
    id: 1,
    title: "Home",
    logo: "home",
    link: "",
  },
];
