import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";

export default function CommentModal({ data, collectionDB }) {
  return (
    <ModalWrapper size='small'>
      <CommentForm
        docId={data.id}
        workspaceId={data.workspaceId}
        spaceId={data.spaceId}
        collectionFolder={collectionDB}
      />
      <CommentList docId={data.id} />
    </ModalWrapper>
  );
}
