import React from "react";
import { Card } from "semantic-ui-react";
import SpaceListItem from "./SpaceListItem";

export default function SpaceList({ spaces, workspaceId }) {
  return (
    <>
      <Card.Group stackable itemsPerRow={4}>
        {spaces.map((space) => (
          <SpaceListItem
            workspaceId={workspaceId}
            space={space}
            key={space.id}
          />
        ))}
      </Card.Group>
    </>
  );
}
