import React, { useState } from "react";
import { Segment } from "semantic-ui-react";

import NavBar from "./NavBar";
import SideBar from "./SideBar";

export default function Layout(props) {
  const [visible, setVisible] = useState(false);
  return (
    <div className={props.class}>
      <NavBar setVisible={setVisible} visible={visible} />
      <SideBar setVisible={setVisible} visible={visible}>
        {props.children}
      </SideBar>
    </div>
  );
}
