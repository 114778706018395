import React from "react";
import { Card } from "semantic-ui-react";
import WorkspaceListItem from "./WorkspaceListItem";

export default function WorkspaceList({ workspaces }) {
  return (
    <>
      <Card.Group stackable itemsPerRow={4}>
        {workspaces.map((workspace) => (
          <WorkspaceListItem workspace={workspace} key={workspace.id} />
        ))}
      </Card.Group>
    </>
  );
}
