import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

export default function Home() {
  const profile = useSelector((state) => state.firebase.profile);

  if (profile.currentWorkspace && profile.currentSpace) {
    return (
      <Route
        render={() => (
          <Redirect
            to={{
              pathname: `/workspaces/${profile.currentWorkspace}/spaces/${profile.currentSpace}/`,
            }}
          />
        )}
      />
    );
  } else if (profile.currentWorkspace) {
    return (
      <Redirect
        to={{
          pathname: `/workspaces/${profile.currentWorkspace}`,
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: `/workspaces`,
        }}
      />
    );
  }
}
