import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import WorkspaceForm from "./WorkspaceForm";

export default function WorkspaceFormModal() {
  return (
    <ModalWrapper size='small'>
      <WorkspaceForm />
    </ModalWrapper>
  );
}
