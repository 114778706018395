import { fetchSampleData } from "../../app/api/mockApi";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../app/async/asyncReducer";
import { FETCH_WORKSPACES } from "./workspaceConstants";

export function loadWorkspaces() {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      const workspaces = await fetchSampleData();
      // dispatch({ type: FETCH_WORKSPACES, payload: workspaces });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}
