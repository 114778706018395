import React from "react";
import { Form, Formik } from "formik";

// import { useDispatch } from "react-redux";
import { Button, Header, Label, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { updateUserPassword } from "./authActions";

const initialValues = {
  newPassword1: "",
  newPassword2: "",
};

const validationSchema = Yup.object({
  newPassword1: Yup.string().required("Password is required"),
  newPassword2: Yup.string()
    .required()
    .oneOf([Yup.ref("newPassword1"), null], "Passwords does not match"),
});

export default function AccountPage() {
  //   const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  return (
    <Segment>
      <Header dividing size='large' content='Account' />
      {currentUser.providerId === "password" && (
        <>
          <Header color='teal' sub content='Change Password' />
          <p> Use this form to change your password </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await updateUserPassword(values);
              } catch (error) {
                setErrors({ auth: error.message });
              } finally {
                // use finally to always execute instead of writing same in try or catch
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, isValid, dirty, errors }) => (
              <Form className='ui form'>
                <MyTextInput
                  name='newPassword1'
                  type='password'
                  placeholder='New Password'
                />
                <MyTextInput
                  name='newPassword2'
                  placeholder='Confirm Password'
                  type='password'
                />
                {errors.auth && (
                  <Label
                    basic
                    color='red'
                    style={{ marginBottom: 10 }}
                    content={errors.auth}
                  />
                )}
                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  type='submit'
                  positive
                  fluid
                  size='large'
                  color='teal'
                  content='Update Password'
                />
              </Form>
            )}
          </Formik>
        </>
      )}
      {currentUser.providerId === "google.com" && (
        <>
          <Header color='teal' sub content='Google Account' />
          <p> Please visit google website to update your account </p>
          <Button
            icon='google'
            color='google plus'
            content='Go to google'
            as={Link}
            to='https://google.com'
          />
        </>
      )}
    </Segment>
  );
}
