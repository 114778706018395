import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Button, Header, Label, Progress, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { commmitFile } from "../../app/firestore/firestoreService";
import { closeModal } from "../../app/common/modals/modalReducer";
import MyFileInput from "../../app/common/form/MyFileInput";
import MyTextArea from "../../app/common/form/MyTextArea";
import { uploadFileToFirebaseStorage } from "../../app/firestore/firebaseService";
import CommitList from "./CommitList";

const initialValues = {
  description: "",
  file1: undefined,
};

const validationSchema = Yup.object({
  description: Yup.string().required("you must provide a description"),
  file1: Yup.mixed().required("you must select a file"),
});

export default function CommitFileForm({
  docId,
  spaceId,
  workspaceId,
  field,
  collection,
}) {
  let acceptedFileExt = `application/${field}`;
  if (field === "skp" || field === "afpub") {
    acceptedFileExt = `.${field}`;
  }

  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  return (
    <Segment basic clearing>
      <Header content='Add a Commit' />
      {progress > 0 && (
        <Progress percent={progress} size='tiny' color='orange' />
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const { file1, ...valuesWithNoFile } = values;
          try {
            const commitId = await dispatch(
              commmitFile(
                valuesWithNoFile,
                collection,
                docId,
                spaceId,
                workspaceId
              )
            );

            await dispatch(
              uploadFileToFirebaseStorage(
                values.file1,
                values.file1.name,
                docId,
                commitId,
                collection,
                "commits",
                workspaceId,
                false,
                field,
                setProgress
              )
            );
            console.log("finished...upload");
            dispatch(closeModal());
            setSubmitting(false);
          } catch (error) {
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, setFieldValue }) => (
          <Form className='ui form'>
            <MyFileInput
              accept={acceptedFileExt}
              name='file1'
              id='file'
              type='file'
              placeholder={`browse a ${field} file`}
            />
            <MyTextArea
              name='description'
              placeholder='Description...'
              rows={2}
            />
            {errors.submit && (
              <Label
                basic
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.submit}
              />
            )}

            <Button
              style={{ backgroundColor: "#ffa600", color: "white" }}
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              floated='right'
              content='Commit'
            />
          </Form>
        )}
      </Formik>

      <CommitList docId={docId} workspaceId={workspaceId} />
    </Segment>
  );
}
