import { ADD_USER } from "./userConstants";

const initialSate = {
  users: [],
  user: {},
};

export default function userReducer(state = initialSate, { type, payload }) {
  switch (type) {
    case ADD_USER:
      return {
        ...state,
        user: payload,
      };

    default:
      return state;
  }
}
