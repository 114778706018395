import { ADD_SPACE, UPDATE_SPACE } from "./spaceConstants";

const initialSate = {
  spaces: [],
  space: {},
};

export default function spaceReducer(state = initialSate, { type, payload }) {
  switch (type) {
    case ADD_SPACE:
      return {
        ...state,
        space: payload,
      };
    case UPDATE_SPACE:
      return {
        ...state,
        space: payload,
      };

    default:
      return state;
  }
}
