import React from "react";
import { Container } from "semantic-ui-react";
import Sketch3dListItem from "./Sketch3dListItem";

export default function Sketch3dList({ cards }) {
  return (
    <Container>
      {cards.map((card) => (
        <Sketch3dListItem card={card} key={card.id} />
      ))}
    </Container>
  );
}
