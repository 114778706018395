import React from "react";
import { useField } from "formik";
import { FormField, Input, Label } from "semantic-ui-react";

export default function MyGeoLocInput({ label, ...props }) {
  const [field, meta, helpers] = useField(props);
  const geoLocation = field.value;
  let newLocation = {
    latitude: geoLocation?.latitude || 0,
    longitude: geoLocation?.longitude || 0,
  };

  return (
    <FormField error={meta.touched && !!meta.error}>
      <label>{label}</label>
      <Input
        // value={field.value.latitude || null}
        placeholder='Latitude...'
        onChange={(event, data) => {
          helpers.setValue({ ...newLocation, latitude: data.value });
        }}
        onBlur={() => helpers.setTouched(true)}
      />
      {meta.touched && meta.error?.latitude ? (
        <Label basic color='red'>
          {meta.error.latitude}
        </Label>
      ) : null}
      <Input
        // value={field.value.longitude || null}
        placeholder='Longitude...'
        onChange={(event, data) => {
          helpers.setValue({ ...newLocation, longitude: data.value });
        }}
        onBlur={() => helpers.setTouched(true)}
      />
      {meta.touched && meta.error?.longitude ? (
        <Label basic color='red'>
          {meta.error.longitude}
        </Label>
      ) : null}

      <Label
        basic
        content={` location: ${newLocation.latitude},${newLocation.longitude}`}
      />
    </FormField>
  );
}
