import React from "react";
import { Container, Menu } from "semantic-ui-react";

export default function SubNavBar(props) {
  return (
    <>
      <Menu
        inverted
        borderless
        style={{
          marginTop: "40px",
          zIndex: "100",
          position: "fixed",
          left: "0",
          width: "100%",
          background: "#6A6A6A",
          borderRadius: "0",
        }}
      >
        <Container>{props.children}</Container>
      </Menu>
    </>
  );
}
