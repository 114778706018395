import React from "react";
import { Card, Icon, Image } from "semantic-ui-react";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";

export default function SpaceListItem({ space, workspaceId }) {
  return (
    <Card
      className='vertical'
      as={NavLink}
      to={`/workspaces/${workspaceId}/spaces/${space.id}/`}
    >
      <div className='img-ratio'>
        <Image
          src={space.imageResizedSmallUrl || null}
          // wrapped
          ui={false}
        />
      </div>

      <Card.Content>
        <Card.Header>{space.title}</Card.Header>
        {space.createdAt && (
          <Card.Meta
            content={`Created In  ${format(
              space.createdAt.toDate(),
              "MMMM d, yyyy"
            )}`}
          />
        )}

        <Card.Description>{space.description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Icon name='user' />
        Users
      </Card.Content>
    </Card>
    // <Segment.Group>
    //   <Segment>
    //     <Item.Group>
    //       <Item>
    //         <Item.Image
    //           as={NavLink}
    //           to={`/workspaces/${workspaceId}/spaces/${space.id}/`}
    //           size='tiny'
    //           circular
    //           src={space.imageResizedSmallUrl || null}
    //         />
    //         <Item.Content>
    //           <Item.Header content={space.title} />
    //           <Item.Meta content={space.description} />
    //           <Item.Extra content={space.category} />
    //         </Item.Content>
    //       </Item>
    //     </Item.Group>
    //   </Segment>
    //   <Segment>{format(space.date.toDate(), "MMMM d, yyyy")}</Segment>
    // </Segment.Group>
  );
}
