import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Checkbox, Header, Menu } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import CardColumn from "../../card/CardColumn";

import SubNavBar from "../../nav/SubNavBar";
import SectionDashboard from "../../section/SectionDashboard";
import SectionList from "../../section/SectionList";
import SupplierList from "./SupplierList";

export default function SupplierDashboard() {
  const { spaceId, workspaceId } = useParams();
  const dispatch = useDispatch();
  const [cancelView, setCancelView] = useState(false);
  return (
    <>
      <SubNavBar>
        <Menu.Item>
          <Button
            icon='plus'
            inverted
            color='orange'
            onClick={() => {
              dispatch(
                openModal({
                  modalType: `SectionFormModal`,
                  modalProps: {
                    workspaceId,
                    spaceId: null,
                    selectedDoc: null,
                    collection: "suppliers",
                  },
                })
              );
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Header
            style={{ color: "#28292B" }}
            id='fonts'
            inverted
            content={"Suppliers"}
          />
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            toggle
            onChange={() => {
              setCancelView(!cancelView);
            }}
          />
        </Menu.Item>
      </SubNavBar>
      <SectionDashboard cancelView={cancelView} sectionId='suppliers'>
        <SectionList>
          <CardColumn collection={"suppliers"}>
            <SupplierList />
          </CardColumn>
        </SectionList>
      </SectionDashboard>
    </>
  );
}
