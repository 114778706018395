import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import SectionForm from "./SectionForm";

export default function SectionFormModal({
  workspaceId,
  spaceId,
  supplierId,
  selectedDoc,
  collection,
}) {
  return (
    <ModalWrapper size='small'>
      <SectionForm
        workspaceId={workspaceId}
        spaceId={spaceId}
        supplierId={supplierId}
        selectedDoc={selectedDoc}
        collection={collection}
      />
    </ModalWrapper>
  );
}
