import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { saveAs } from "file-saver";
import CardListItem from "../../card/CardListItem";

export default function Sketch2dListItem({ card }) {
  const dispatch = useDispatch();

  return (
    <CardListItem card={card} collection='sketch2ds' direction='vertical'>
      <>
        <Button
          floated='right'
          icon='file alternate'
          onClick={() => {
            dispatch(
              openModal({
                modalType: "CommentModal",
                modalProps: { data: card, collectionDB: "sketch2ds" },
              })
            );
          }}
        />
      </>
    </CardListItem>
  );
}
