import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { saveAs } from "file-saver";
import CardListItem from "../../card/CardListItem";
import { useMediaQuery } from "react-responsive";

export default function Sketch3dListItem({ card }) {
  const ismobile = useMediaQuery({ query: "(max-width: 768px)" });
  const dispatch = useDispatch();

  return (
    <CardListItem
      card={card}
      collection='sketch3ds'
      direction={ismobile ? "vertical" : "horizontal"}
      compact={ismobile ? true : false}
    >
      <>
        <Button.Group size='tiny' floated='right'>
          <Button
            icon='file alternate'
            onClick={() => {
              dispatch(
                openModal({
                  modalType: "CommentModal",
                  modalProps: { data: card, collectionDB: "sketch3ds" },
                })
              );
            }}
          />
        </Button.Group>

        <Button.Group size='tiny' floated='right'>
          {card.skpUrl && (
            <Button
              // as='a'
              onClick={() => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = "blob";
                xhr.onload = (event) => {
                  console.log("loaded...");
                  var blob = xhr.response;
                  saveAs(blob, `${card.title}.skp`);
                };
                xhr.open("GET", card.skpUrl);
                xhr.send();
              }}
              icon='download'
            />
          )}
          <Button
            content='SKP'
            icon='upload'
            onClick={() => {
              dispatch(
                openModal({
                  modalType: "CommitFileFormModal",
                  modalProps: {
                    data: card,
                    field: "skp",
                    collection: "sketch3ds",
                  },
                })
              );
            }}
          />
        </Button.Group>
      </>
    </CardListItem>
  );
}
