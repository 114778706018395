import React from "react";
import { Card, Container } from "semantic-ui-react";
import LayoutListItem from "./LayoutListItem";

export default function LayoutList({ cards }) {
  return (
    <Container>
      {cards.map((card) => (
        <LayoutListItem card={card} key={card.id} />
      ))}
    </Container>
  );
}
