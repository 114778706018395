import React from "react";
import { Card, Icon, Image } from "semantic-ui-react";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";

export default function WorkspaceListItem({ workspace }) {
  return (
    <Card className='vertical' as={NavLink} to={`/workspaces/${workspace.id}`}>
      <div className='img-ratio'>
        <Image
          src={workspace.logo || null}
          // wrapped
          ui={false}
        />
      </div>
      <Card.Content>
        <Card.Header>{workspace.title}</Card.Header>
        {workspace.createdAt && (
          <Card.Meta
            content={`Created In  ${format(
              workspace.createdAt.toDate(),
              "MMMM d, yyyy"
            )}`}
          />
        )}

        <Card.Description>{workspace.description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Icon name='user' />
        {workspace.members.length} Users
      </Card.Content>
    </Card>
  );
}
