import { delay } from "../common/util/util";
import { workspacesData } from "./sampleData";

export function fetchSampleData() {
  return delay(3000).then(function () {
    return Promise.resolve(workspacesData);
  });
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function getRandomLogo() {
  return `https://picsum.photos/id/${getRandomInt(100, 200)}/100?grayscale`;
}
