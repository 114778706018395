import { FETCH_WORKSPACES, ADD_WORKSPACE } from "./workspaceConstants";

const initialSate = {
  workspaces: [],
  workspace: {},
};

export default function workspaceReducer(
  state = initialSate,
  { type, payload }
) {
  switch (type) {
    case FETCH_WORKSPACES:
      return {
        ...state,
        workspaces: payload,
      };
    case ADD_WORKSPACE:
      return {
        ...state,
        workspace: payload,
      };

    default:
      return state;
  }
}
