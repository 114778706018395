import React from "react";
import { useField } from "formik";
import { Dimmer, FormField, Label, Loader } from "semantic-ui-react";

export default function MyTextArea({ isSubmitting, label, ...props }) {
  const [field, meta] = useField(props);
  // dimmer added to fix the issue of loader not visibil in modal

  return (
    <FormField error={meta.touched && !!meta.error}>
      <div style={{ position: "relative" }}>
        <Dimmer active={isSubmitting} inverted>
          <Loader active={isSubmitting} inline='centered' />
        </Dimmer>
        <label>{label}</label>
        <textarea {...field} {...props} />
        {meta.touched && meta.error ? (
          <Label basic color='red'>
            {meta.error}
          </Label>
        ) : null}
      </div>
    </FormField>
  );
}
