import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Container } from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";

export default function UnauthPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openModal({ modalType: "LoginForm" }));
  }, [dispatch]); // very stange to add dispatch []

  return (
    <>
      <Container style={{ marginTop: 20, height: "100vmax" }}>
        <Button
          onClick={() =>
            dispatch(
              openModal({ modalType: "TestModal", modalProps: { data: 33 } })
            )
          }
          content='Open Modal'
          color='teal'
        />
      </Container>
    </>
  );
}
