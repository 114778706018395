import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import CardListItem from "../../card/CardListItem";

export default function VisualListItem({ card }) {
  const dispatch = useDispatch();

  return (
    <CardListItem
      card={card}
      collection='visuals'
      direction='vertical'
      compact={true}
    >
      <>
        <Button
          floated='right'
          icon='file alternate'
          onClick={() => {
            dispatch(
              openModal({
                modalType: "CommentModal",
                modalProps: { data: card, collectionDB: "visuals" },
              })
            );
          }}
        />
      </>
    </CardListItem>
  );
}
