import React from "react";
import { Card, Container } from "semantic-ui-react";
import Sketch2dListItem from "./Sketch2dListItem";

export default function Sketch2dList({ cards }) {
  return (
    <>
      <Container style={{ marginTop: "1rem" }}>
        <Card.Group stackable itemsPerRow={4}>
          {cards.map((card) => (
            <Sketch2dListItem card={card} key={card.id} />
          ))}
        </Card.Group>
      </Container>
    </>
  );
}
