import React from "react";
import { Container } from "semantic-ui-react";
import SupplierListItem from "./SupplierListItem";

export default function SupplierList({ cards }) {
  return (
    <Container>
      {cards.map((card) => (
        <SupplierListItem card={card} key={card.id} />
      ))}
    </Container>
  );
}
