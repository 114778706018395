import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestoreConnect,
} from "react-redux-firebase";
import { useParams } from "react-router";
import { Button, Card, Container, Grid, Header, Menu } from "semantic-ui-react";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import SubNavBar from "../nav/SubNavBar";
import SpaceForm from "./SpaceForm";
import SpaceList from "./SpaceList";
import SpaceListItemPlaceholder from "./SpaceListItemPlaceholder";

export default function SpaceDashboard() {
  const { workspaceId, spaceId } = useParams();
  const dispatch = useDispatch();

  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);
  let spacesQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    spacesQuery = {
      collection: "spaces",
      orderBy: ["createdAt", "desc"],
      storeAs: `spaces/${workspaceId}`,
      where: [
        ["workspaceId", "==", workspaceId],
        ["isCancelled", "==", false],
      ],
    };
  } else {
    spacesQuery = {
      collection: "workspaces",
      orderBy: ["createdAt", "desc"],
    };
  }

  useFirestoreConnect(() => [spacesQuery]);

  const spaces = useSelector(
    (state) => state.firestore.ordered[`spaces/${workspaceId}`]
  );
  return (
    <>
      <SubNavBar>
        <Menu.Item>
          <Button
            circular
            inverted
            color='orange'
            icon='plus'
            onClick={() => {
              dispatch(
                openModal({
                  modalType: "SpaceModal",
                  modalProps: { workspaceId, selectedSpace: undefined },
                })
              );
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Header
            style={{ color: "#28292B" }}
            id='fonts'
            inverted
            content='Projects'
          />
        </Menu.Item>
      </SubNavBar>

      <Container
        style={{
          marginTop: "8rem",
        }}
      >
        {isLoaded(spaces) && !isEmpty(spaces) ? (
          <SpaceList workspaceId={workspaceId} spaces={spaces} />
        ) : (
          <>
            <Card.Group stackable itemsPerRow={4}>
              <SpaceListItemPlaceholder />
              <SpaceListItemPlaceholder />
            </Card.Group>
          </>
        )}
      </Container>
    </>
  );
}
