import { toast } from "react-toastify";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from "../../app/async/asyncReducer";
import { delay } from "../../app/common/util/util";

export const INCREMENT_COUNTER = "INCREMENT_COUNTER";
export const DECREMENT_COUNTER = "DECREMENT_COUNTER";

export function increament(amount) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      await delay(3000);
      dispatch({ type: INCREMENT_COUNTER, payload: amount });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}
export function decreament(amount) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      await delay(3000);

      dispatch({ type: DECREMENT_COUNTER, payload: amount });
      dispatch(asyncActionFinish());
      // throw "opps error 101";
    } catch (error) {
      dispatch(asyncActionError(error));
      toast.error(error);
    }
  };
}

const initialSate = {
  data: 33,
};

export default function testReducer(state = initialSate, { type, payload }) {
  switch (type) {
    case INCREMENT_COUNTER:
      return {
        ...state,
        data: state.data + payload,
      };
    case DECREMENT_COUNTER:
      return {
        ...state,
        data: state.data - payload,
      };
    default:
      return state;
  }
}
