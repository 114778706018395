import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { saveAs } from "file-saver";
import CardListItem from "../../card/CardListItem";

export default function PresentationListItem({ card }) {
  const dispatch = useDispatch();

  return (
    <CardListItem card={card} collection='presentations' direction='horizontal'>
      <>
        <Button.Group size='tiny' floated='right'>
          <Button
            // content='Comment'

            icon='file alternate'
            onClick={() => {
              dispatch(
                openModal({
                  modalType: "CommentModal",
                  modalProps: { data: card, collectionDB: "presentations" },
                })
              );
            }}
          />
        </Button.Group>
        <Button.Group size='tiny' floated='right'>
          {card.pdfUrl && (
            <Button
              // as='a'
              onClick={() => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = "blob";
                xhr.onload = (event) => {
                  console.log("loaded...");
                  var blob = xhr.response;
                  saveAs(blob, `${card.title}.pdf`);
                };
                xhr.open("GET", card.pdfUrl);
                xhr.send();
              }}
              icon='download'
            />
          )}

          <Button
            content='PDF'
            icon='upload'
            onClick={() => {
              dispatch(
                openModal({
                  modalType: "CommitFileFormModal",
                  modalProps: {
                    data: card,
                    field: "pdf",
                    collection: "presentations",
                  },
                })
              );
            }}
          />
        </Button.Group>

        <Button.Group size='tiny' floated='right'>
          {card.afpubUrl && (
            <Button
              // as='a'
              onClick={() => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = "blob";
                xhr.onload = (event) => {
                  console.log("loaded...");
                  var blob = xhr.response;
                  saveAs(blob, `${card.title}.afpub`);
                };
                xhr.open("GET", card.afpubUrl);
                xhr.send();
              }}
              icon='download'
            />
          )}
          <Button
            content='AFPUB'
            icon='upload'
            onClick={() => {
              dispatch(
                openModal({
                  modalType: "CommitFileFormModal",
                  modalProps: {
                    data: card,
                    field: "afpub",
                    collection: "presentations",
                  },
                })
              );
            }}
          />
        </Button.Group>
      </>
    </CardListItem>
  );
}
