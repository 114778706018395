import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import SpaceForm from "./SpaceForm";

export default function SpaceModal({ workspaceId, selectedSpace }) {
  return (
    <ModalWrapper size='small'>
      <SpaceForm workspaceId={workspaceId} selectedSpace={selectedSpace} />
    </ModalWrapper>
  );
}
